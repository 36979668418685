import { useState } from 'react';
import OrderFormTemplate from './OrderFormTemplate';
import LabelDate from './LabelDate';
import LabelTime from './LabelTime';
import CheckBox from './CheckBox';
import Radio from './Radio';
import ModalPopup from './ModalPopup';
import Button from './Button';
import { minDate, addDays } from '../utils/miscellaneous';
import InfoBlock from './InfoBlock';
import { createPortal } from 'react-dom';
import { isMuvaFreight } from '../utils/whitelabeling';
import LabelTextArea from './LabelTextArea';
import './OrderForm3.scss';

const warnTerm = 21;

export default function OrderForm3({ onNext, onBack, status, formik }) {
  const [showShortTermWarn, setShowShortTermWarn] = useState(false);
  const [tmpPickUpDate, setTmpPickUpDate] = useState();

  const setPickUpDate = (newDate) => {
    formik.setFieldValue('pickUpDate', newDate);
    if (addDays(newDate, 1) > formik.values.storageDate)
      formik.setFieldValue('storageDate', addDays(newDate, 1));
  };

  return (
    <OrderFormTemplate
      header='Pick Up Details'
      status={status}
      onNext={onNext}
      onBack={onBack}
      disabled={formik.values.noPacking === undefined}
    >
      <div className='order-form-3-wrapper'>
        <LabelDate
          label='Pick Up Date*'
          value={formik.values.pickUpDate}
          onChange={(newDate) => {
            if (addDays(new Date(), warnTerm) > newDate) {
              setTmpPickUpDate(newDate);
              setShowShortTermWarn(true);
            } else setPickUpDate(newDate);
          }}
          minDate={minDate()}
        />
        <LabelTime
          label='Pick Up Time*'
          name='pickUpTime'
          value={formik.values.pickUpTime}
          onChange={formik.handleChange}
        />
        {!isMuvaFreight() && formik.values.moveType !== 'Freight' && (
          <div className='checkbox-date-wrapper'>
            <CheckBox
              label='Require storage'
              checked={formik.values.storageRequired}
              {...formik.getFieldProps('storageRequired')}
            />
            <LabelDate
              label='Estimated Storage End Date*'
              value={formik.values.storageDate}
              onChange={(newVal) => formik.setFieldValue('storageDate', newVal)}
              disabled={!formik.values.storageRequired}
              minDate={addDays(formik.values.pickUpDate, 1)}
            />
          </div>
        )}
        <div className='checkboxes-block-wrapper'>
          <label>Packing Options:</label>
          <div className='radio-wrapper'>
            <div className='radio-i-wrap'>
              <Radio
                value='false'
                label='Company pack'
                name='noPacking'
                checked={formik.values.noPacking === false}
                onChange={() => formik.setFieldValue('noPacking', false)}
              />
              <InfoBlock text='The movers pack all goods, including small and fragile items' />
            </div>
            <div className='radio-i-wrap'>
              <Radio
                value='true'
                label='Pack by owner'
                name='noPacking'
                checked={formik.values.noPacking === true}
                onChange={() => formik.setFieldValue('noPacking', true)}
              />
              <InfoBlock text='You pack all items into boxes' />
            </div>
          </div>
          {!isMuvaFreight() && formik.values.moveType !== 'Freight' && (
            <label>
              If you want a custom pack, select 'Pack by owner' and list items
              during your video
            </label>
          )}
        </div>
        {(isMuvaFreight() || formik.values.moveType === 'Freight') && (
          <LabelTextArea
            label='Content description and container requirements:'
            name='requirements'
            value={formik.values.requirements}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className='requirements'
          />
        )}
        {document.getElementById('order-form') &&
          createPortal(
            <ModalPopup
              className='modal-short-time-warn'
              show={showShortTermWarn}
              onClose={() => setShowShortTermWarn(false)}
            >
              <p>
                We do our best to accommodate all requests, but moves in less
                than {warnTerm} days may incur a short notice surcharge
              </p>
              <div className='btn-wrapper'>
                <Button
                  background='whitesmoke'
                  color='black'
                  onClick={() => setShowShortTermWarn(false)}
                >
                  CANCEL
                </Button>
                <Button
                  onClick={() => {
                    setPickUpDate(tmpPickUpDate);
                    setShowShortTermWarn(false);
                  }}
                >
                  AGREE
                </Button>
              </div>
            </ModalPopup>,
            document.getElementById('order-form')
          )}
      </div>
    </OrderFormTemplate>
  );
}
