import { useState } from 'react';
import PageTemplate from './PageTemplate';
import SignInPanel from './SignInPanel';
import IntroText from './IntroText';
import Footer from './Footer';
import './Home.scss';

export default function Home({ intro: Intro = IntroText }) {
  return (
    <div className='home'>
      <PageTemplate home footer>
        <div className='home-content'>
          <Intro />
          <SignInPanel navigatePath='/order' />
        </div>
      </PageTemplate>
      <Footer />
    </div>
  );
}
