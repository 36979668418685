import Button from './Button';
import ArrowIcon from '../assets/images/arrow_left_icon.png';
import './ToQuotesListBtn.scss';

export default function ToQuotesListBtn({ onClick, invisible }) {
  return (
    <Button
      onClick={onClick}
      icon={ArrowIcon}
      background='transparent'
      nohover
      className={'to-quotes-list-btn' + (invisible ? ' invisible' : '')}
    >
      TO QUOTES LIST
    </Button>
  );
}
