import { useEffect } from 'react';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isEmail from 'validator/lib/isEmail';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CheckBox from './CheckBox';
import LabelInput from './LabelInput';
import OrderFormTemplate1Btn from './OrderFormTemplate1Btn';
import { auth, db } from '../utils/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { query, collection, where, onSnapshot } from 'firebase/firestore';
import './HaulerInfo.scss';

const initialValues = {
  companyName: '',
  webSite: '',
  primaryPersonName: '',
  primaryPersonPhone: '',
  primaryPersonEmail: '',
  estimatesByAnotherPerson: false,
  estimatesPersonName: '',
  estimatesPersonPhone: '',
  estimatesPersonEmail: '',
  geoNorthAmerica: false,
  geoAsiaPacific: false,
  license: '',
  ports: '',
};

export default function HaulerInfo({ status, onNext }) {
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (!user) return;

    const { uid } = user;

    const haulersQuery = query(
      collection(db, 'haulers'),
      where('userID', '==', uid)
    );

    const unsub = onSnapshot(haulersQuery, (haulersSnapshot) => {
      const haulers = [];
      haulersSnapshot.forEach((doc) => haulers.push(doc.data()));
      console.log(haulers);
    });

    return unsub;
  }, [user]);

  const validationSchema = new Yup.ObjectSchema({
    primaryPersonName: Yup.string().required('Required'),
    primaryPersonPhone: Yup.string()
      .test('phone-validation', 'Invalid phone', (value) =>
        isMobilePhone(value, 'en-US')
      )
      .required('Required'),
    primaryPersonEmail: Yup.string()
      .test('email-validation', 'Invalid email', (value) =>
        isEmail(value, {
          domain_specific_validation: true,
          allow_utf8_local_part: false,
        })
      )
      .required('Required'),
    estimatesPersonName: Yup.string().when('estimatesByAnotherPerson', {
      is: (value) => value,
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema,
    }),
    estimatesPersonPhone: Yup.string().when('estimatesByAnotherPerson', {
      is: (value) => value,
      then: (schema) =>
        schema
          .test('phone-validation', 'Invalid phone', (value) =>
            isMobilePhone(value, 'en-US')
          )
          .required('Required'),
      otherwise: (schema) => schema,
    }),
    estimatesPersonEmail: Yup.string().when('estimatesByAnotherPerson', {
      is: (value) => value,
      then: (schema) =>
        schema
          .test('email-validation', 'Invalid email', (value) =>
            isEmail(value, {
              domain_specific_validation: true,
              allow_utf8_local_part: false,
            })
          )
          .required('Required'),
      otherwise: (schema) => schema,
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      onNext();
    },
  });

  return (
    <OrderFormTemplate1Btn
      header='General info'
      btnText='CONTINUE'
      status={status}
      form='hauler-info'
      btnType='submit'
    >
      <form id='hauler-info' onSubmit={formik.handleSubmit}>
        <LabelInput
          label='Company Name'
          placeholder='Company name'
          name='companyName'
          {...formik.getFieldProps('companyName')}
        />
        <LabelInput
          label='Website'
          placeholder='Website'
          name='webSite'
          {...formik.getFieldProps('webSite')}
        />
        <div className='block'>
          <p>Primary contact person</p>
          <LabelInput
            label='Name*'
            placeholder='Name'
            name='primaryPersonName'
            {...formik.getFieldProps('primaryPersonName')}
            error={formik.errors.primaryPersonName}
          />
          <div className='horizont'>
            <LabelInput
              label='Phone*'
              placeholder='Phone'
              name='primaryPersonPhone'
              {...formik.getFieldProps('primaryPersonPhone')}
              error={formik.errors.primaryPersonPhone}
            />
            <LabelInput
              label='Email*'
              placeholder='Email'
              name='primaryPersonEmail'
              {...formik.getFieldProps('primaryPersonEmail')}
              error={formik.errors.primaryPersonEmail}
            />
          </div>
        </div>
        <div className='block'>
          <CheckBox
            label='Estimates are provided by another person'
            name='estimatesByAnotherPerson'
            checked={formik.values.estimatesByAnotherPerson}
            {...formik.getFieldProps('estimatesByAnotherPerson')}
          />
          <LabelInput
            label='Name*'
            placeholder='Name'
            name='estimatesPersonName'
            {...formik.getFieldProps('estimatesPersonName')}
            disabled={!formik.values.estimatesByAnotherPerson}
            error={formik.errors.estimatesPersonName}
          />
          <div className='horizont'>
            <LabelInput
              label='Phone*'
              placeholder='Phone'
              name='estimatesPersonPhone'
              {...formik.getFieldProps('estimatesPersonPhone')}
              disabled={!formik.values.estimatesByAnotherPerson}
              error={formik.errors.estimatesPersonPhone}
            />
            <LabelInput
              label='Email*'
              placeholder='Email'
              name='estimatesPersonEmail'
              {...formik.getFieldProps('estimatesPersonEmail')}
              disabled={!formik.values.estimatesByAnotherPerson}
              error={formik.errors.estimatesPersonEmail}
            />
          </div>
        </div>
        <div className='block'>
          <p>Your company's geography</p>
          <div className='horizont all-devices'>
            <CheckBox
              label='North America'
              className='width-50'
              name='geoNorthAmerica'
              checked={formik.values.geoNorthAmerica}
              {...formik.getFieldProps('geoNorthAmerica')}
            />
            <CheckBox
              label='Asia Pacific'
              className='width-50'
              name='geoAsiaPacific'
              checked={formik.values.geoAsiaPacific}
              {...formik.getFieldProps('geoAsiaPacific')}
            />
          </div>
        </div>
        <LabelInput
          label='Type of License'
          placeholder='Type of license'
          name='license'
          {...formik.getFieldProps('license')}
        />
        <LabelInput
          label='Approved ports to pick up goods'
          placeholder='Ports'
          name='ports'
          {...formik.getFieldProps('ports')}
        />
      </form>
    </OrderFormTemplate1Btn>
  );
}
