import { useFormik } from 'formik';
import * as Yup from 'yup';
import LabelDate from './LabelDate';
import LabelInput from './LabelInput';
import OrderFormTemplate1Btn from './OrderFormTemplate1Btn';
import './PreventDeadheading.scss';

const initialValues = {
  drivingFrom: '',
  drivingTo: '',
  earliestDate: new Date(),
  latestDate: new Date(),
};

export default function PreventDeadheading({ status, onNext }) {
  const validationSchema = new Yup.ObjectSchema({
    drivingFrom: Yup.string().required('Required'),
    drivingTo: Yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      onNext();
    },
  });

  return (
    <OrderFormTemplate1Btn
      header='Details'
      status={status}
      btnText='CONTINUE'
      btnType='submit'
      form='hauler-request'
    >
      <form id='hauler-request' onSubmit={formik.handleSubmit}>
        <div className='block'>
          <p>Where are you driving</p>
          <div className='horizont'>
            <LabelInput
              label='From*'
              placeholder='Driving from'
              name='drivingFrom'
              {...formik.getFieldProps('drivingFrom')}
              error={formik.errors.drivingFrom}
            />
            <LabelInput
              label='To*'
              placeholder='Driving to'
              name='drivingTo'
              {...formik.getFieldProps('drivingTo')}
              error={formik.errors.drivingTo}
            />
          </div>
        </div>
        <div className='block'>
          <p>When you can pickup the goods</p>
          <div className='horizont'>
            <LabelDate
              label='Earliest'
              className='width-50'
              minDate={new Date()}
              value={formik.values.earliestDate}
              onChange={(newVal) => {
                formik.setFieldValue('earliestDate', newVal);
                if (newVal > formik.values.latestDate)
                  formik.setFieldValue('latestDate', newVal);
              }}
            />
            <LabelDate
              label='Latest'
              className='width-50'
              minDate={formik.values.earliestDate}
              value={formik.values.latestDate}
              onChange={(newVal) => formik.setFieldValue('latestDate', newVal)}
            />
          </div>
        </div>
      </form>
    </OrderFormTemplate1Btn>
  );
}
