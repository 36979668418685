import Button from './Button';
import './IntroTextMuvaFreight.scss';

export default function IntroTextMuvaFreight({ onShipClick, onJoinClick }) {
  return (
    <div className='intro-text-muva-freight'>
      <h1 className='welcome'>
        The Future of the
        <br />
        Supply Chain is Here
      </h1>
      <h3>
        Muva technology connects&nbsp;you&nbsp;with&nbsp;the
        <br />
        expert network for all&nbsp;your&nbsp;business
        <br />
        transportation needs
      </h3>
      <div className='btn-wrapper'>
        <Button onClick={onShipClick}>
          Ship Your <br />
          Freight
        </Button>
        <Button onClick={onJoinClick}>
          Join Hauler
          <br /> Network
        </Button>
      </div>
    </div>
  );
}
