import { useState } from 'react';
import PageTemplate from './PageTemplate';
import SignInPanel from './SignInPanel';
import IntroTextMuvaFreight from './IntroTextMuvaFreight';
import './HomeMuvaFreight.scss';

export default function HomeMuvaFreight() {
  const [navigatePath, setNavigatePath] = useState('');
  const [showSignInPanel, setShowSignInPanel] = useState(false);

  return (
    <div className='home-muva-freight'>
      <PageTemplate home>
        <div
          className={'centered-block ' + (showSignInPanel ? 'hide' : 'show')}
        >
          <IntroTextMuvaFreight
            onShipClick={() => {
              setNavigatePath('/order');
              setShowSignInPanel(true);
            }}
            onJoinClick={() => {
              setNavigatePath('/market');
              setShowSignInPanel(true);
            }}
          />
        </div>
        <div
          className={'centered-block ' + (showSignInPanel ? 'show' : 'hide')}
        >
          <SignInPanel navigatePath={navigatePath} />
        </div>
      </PageTemplate>
    </div>
  );
}
