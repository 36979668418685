import { useState } from 'react';
import Button from './Button';
import ShowPwdIcon from '../assets/images/show-pwd-btn.png';
import HidePwdIcon from '../assets/images/hide-pwd-btn.png';
import './LabelInput.scss';

export default function LabelInput({
  label,
  type = 'text',
  maxLength,
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  error,
  autoFocus,
  className,
  disabled,
}) {
  const [showHide, setShowHide] = useState('hide');

  return (
    <div
      className={
        'label-input' +
        (disabled ? ' disabled' : '') +
        (className ? ' ' + className : '')
      }
    >
      <label className={error ? 'error' : ''}>
        {error ? label + ': ' + error : label}
      </label>
      <input
        ref={(el) => {
          if (autoFocus && el) el.focus();
        }}
        type={type === 'password' && showHide === 'show' ? 'text' : type}
        style={type === 'password' ? { paddingRight: 32 } : undefined}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        maxLength={maxLength}
      />
      {type === 'password' && (
        <Button
          icon={showHide === 'hide' ? ShowPwdIcon : HidePwdIcon}
          small
          className='show-pwd-btn'
          background='transparent'
          nohover
          onClick={() => setShowHide(showHide === 'show' ? 'hide' : 'show')}
        />
      )}
    </div>
  );
}
