import { useState, useRef } from 'react';
import PageTemplate from './PageTemplate';
import HaulerInfo from './HaulerInfo';
import PreventDeadheading from './PreventDeadheading';
import WellDone from './WellDone';
import { scrollTop } from '../utils/miscellaneous';
import './MarketVisual.scss';

export default function MarketVisual() {
  const [step, setStep] = useState(0);
  const refScrollArea = useRef(null);

  const formStatus = (formIndex) => {
    if (step > formIndex) return 'left';
    if (step < formIndex) return 'right';
    return 'active';
  };

  const nextStep = () => {
    scrollTop(refScrollArea);
    setStep((curStep) => curStep + 1);
  };

  const prevStep = () => {
    scrollTop(refScrollArea);
    setStep((curStep) => curStep - 1);
  };

  return (
    <PageTemplate refScrollArea={refScrollArea}>
      <div className='market'>
        <HaulerInfo status={formStatus(0)} onNext={nextStep} />
        <PreventDeadheading status={formStatus(1)} onNext={nextStep} />
        <WellDone status={formStatus(2)} onPrev={prevStep} />
      </div>
    </PageTemplate>
  );
}
