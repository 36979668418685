import OrderFormTemplate1Btn from './OrderFormTemplate1Btn';
import './WellDonde.scss';

export default function WellDone({ status, onPrev }) {
  return (
    <OrderFormTemplate1Btn
      header='Well Done!'
      onClick={onPrev}
      status={status}
      btnText='BACK'
    >
      <div className='well-done'>
        <p>We've received your application and will contact you shortly.</p>
        <p>We will do the best to prevent your deadheading.</p>
      </div>
    </OrderFormTemplate1Btn>
  );
}
