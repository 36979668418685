import { useState, useEffect, useRef, useMemo } from 'react';
import PageTemplate from './PageTemplate';
import ToQuotesListBtn from './ToQuotesListBtn';
import StepClock from './StepClock';
import QuotesList from './QuotesList';
import OrderForm0 from './OrderForm0';
import OrderForm1 from './OrderForm1';
import OrderForm2 from './OrderForm2';
import OrderForm3 from './OrderForm3';
import OrderForm4 from './OrderForm4';
import OrderForm5 from './OrderForm5';
import OrderForm7 from './OrderForm7';
import Review from './Review';
import Pending from './Pending';
import Info from './Info';
import Chat from '../containers/Chat';
import { adminState } from '../utils/states';
import { isMuvaFreight } from '../utils/whitelabeling';
import { scrollTop } from '../utils/miscellaneous';
import './OrderVisual.scss';

export default function OrderVisual({
  formik,
  save,
  awaitFlag,
  orderId,
  state,
  assigns,
  submitOrder,
  videosUrls,
  orderCancel,
  orderCreate,
  videoAdd,
  videoSave,
  uploadingProgress,
  quotes,
  currentQuote,
  setCurrentQuote,
  addressValidateFrom,
  addressValidateTo,
}) {
  const [step, setStep] = useState(0);
  const [noTransition, setNoTransition] = useState(false);
  const refScrollArea = useRef(null);

  // Seting current order step
  useEffect(() => {
    const {
      moveType,
      zipFrom,
      zipTo,
      companyName,
      firstName,
      lastName,
      email,
      phone,
      storageDate,
      storageRequired,
      addressFrom,
      cityFrom,
      bedroomsFrom,
      addressTo,
      cityTo,
      bedroomsTo,
      videos,
    } = formik.values;

    const getCurStep = () => {
      let step = 1;

      if (zipFrom && zipTo) {
        step = 3;

        if (
          firstName &&
          lastName &&
          email &&
          phone &&
          (moveType !== 'Freight' || companyName)
        ) {
          step = 4;

          if (!storageRequired || storageDate) {
            step = 5;

            if (
              addressFrom &&
              cityFrom &&
              (bedroomsFrom || moveType === 'Freight')
            ) {
              step = 6;

              if (
                addressTo &&
                cityTo &&
                (bedroomsTo || moveType === 'Freight')
              ) {
                step = 7;

                if (videos.length > 0) {
                  step = 8;

                  if (state !== adminState.init) {
                    step = 9;
                  }
                }
              }
            }
          }
        }
      }

      return step;
    };

    if (step === 0 && currentQuote !== null) {
      setNoTransition(true);
      setStep(getCurStep());
      scrollTop(refScrollArea);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  // Back to quotes list
  useEffect(() => {
    if (currentQuote === null) setStep(0);
    scrollTop(refScrollArea);
  }, [currentQuote]);

  //Disable noTransition
  useEffect(() => {
    if (noTransition) setTimeout(() => setNoTransition(false), 500);
  }, [noTransition]);

  const nextStep = () => {
    scrollTop(refScrollArea);
    setStep((curStep) => {
      //For Muva Freight skip step 2
      if (isMuvaFreight() && curStep === 1) {
        setNoTransition(true);
        return 3;
      }
      return curStep + 1;
    });
  };

  const prevStep = () => {
    scrollTop(refScrollArea);
    setStep((curStep) => {
      //For Muva Freight skip step 2
      if (isMuvaFreight() && curStep === 3) {
        setNoTransition(true);
        return 1;
      }
      return curStep - 1;
    });
  };

  const setStepWrapper = (step) => {
    scrollTop(refScrollArea);
    setNoTransition(true);
    setStep(step);
  };

  const formStatus = (formIndex) => {
    const noTransitionClass = noTransition ? 'no-transition ' : '';
    if (step > formIndex) return noTransitionClass + 'left';
    if (step < formIndex) return noTransitionClass + 'right';
    return 'active';
  };

  const chatOrderId = useMemo(() => {
    if (step > 0) return orderId;

    return quotes.sort((a, b) => {
      if (
        (a.state === adminState.new ||
          a.state === adminState.review ||
          a.state === adminState.booked ||
          a.state === adminState.ongoing) &&
        (b.state === adminState.init ||
          b.state === adminState.completed ||
          b.state === adminState.adminRejected ||
          b.state === adminState.canceled)
      )
        return -1;

      if (
        a.state === adminState.init &&
        (b.state === adminState.completed ||
          b.state === adminState.adminRejected ||
          b.state === adminState.canceled)
      )
        return -1;

      if (a.details.moveDate > b.details.moveDate) return -1;

      return 1;
    })[0]?.id;
  }, [orderId, quotes, step]);

  return (
    <PageTemplate refScrollArea={refScrollArea}>
      <div className='order-wrapper'>
        <form id='order-form'>
          <QuotesList
            status={formStatus(0)}
            awaitFlag={awaitFlag}
            quotes={quotes}
            setCurrentQuote={setCurrentQuote}
            orderCreate={orderCreate}
          />
          <OrderForm0
            onNext={() => {
              save();
              nextStep();
            }}
            status={formStatus(1)}
            formik={formik}
            awaitFlag={awaitFlag}
          />
          <OrderForm1
            onNext={() => {
              save();
              nextStep();
            }}
            onBack={prevStep}
            status={formStatus(2)}
            formik={formik}
          />
          <OrderForm2
            onNext={() => {
              save();
              nextStep();
            }}
            onBack={prevStep}
            status={formStatus(3)}
            formik={formik}
          />
          <OrderForm3
            onNext={() => {
              save();
              nextStep();
            }}
            onBack={prevStep}
            status={formStatus(4)}
            formik={formik}
          />
          <OrderForm4
            onNext={() => {
              save();
              nextStep();
            }}
            onBack={prevStep}
            status={formStatus(5)}
            formik={formik}
            addressValidate={addressValidateFrom}
          />
          <OrderForm5
            onNext={() => {
              save();
              nextStep();
            }}
            onBack={prevStep}
            status={formStatus(6)}
            formik={formik}
            addressValidate={addressValidateTo}
          />
          <OrderForm7
            onNext={async () => {
              await videoSave();
              nextStep();
            }}
            onBack={prevStep}
            status={formStatus(7)}
            formik={formik}
            videosUrls={videosUrls}
            uploadingProgress={uploadingProgress}
          />
          <Review
            status={formStatus(8)}
            onNext={async () => {
              try {
                await submitOrder();
                nextStep();
              } catch (error) {
                return error.message;
              }
            }}
            setStep={setStepWrapper}
            formik={formik}
            videosUrls={videosUrls}
          />
          <Pending
            status={formStatus(9)}
            onNext={nextStep}
            onCancel={orderCancel}
            onAddVideo={videoAdd}
            uploadingProgress={uploadingProgress}
            orderId={orderId}
            state={state}
            assigns={assigns}
            awaitFlag={awaitFlag}
            formik={formik}
          />
          <Info
            status={formStatus(10)}
            onBack={prevStep}
            formik={formik}
            videosUrls={videosUrls}
          />
        </form>
        <div className='top-items-wrap'>
          <ToQuotesListBtn
            onClick={() => setCurrentQuote(null)}
            invisible={step === 0}
          />
          <StepClock step={step} min={isMuvaFreight() ? 3 : 2} max={8} />
        </div>
      </div>
      {quotes.length > 0 && <Chat orderId={chatOrderId} />}
    </PageTemplate>
  );
}
